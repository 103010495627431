export const ENGLISH = 'English';
export const ENGLISH_CODE = 'en';
export const US_LOCALE = 'en-US';
export const ENGLISH_FLAG = 'English flag';

export const CHINESE = '中文';
export const CHINESE_CODE = 'zh';
export const CHINESE_FLAG = 'Chinese flag';

export const MEXICAN = 'Español';
export const MEXICAN_CODE = 'mx';
export const MEXICAN_FLAG = 'Mexican flag';

export const MON = 'Mon';
export const TUE = 'Tue';
export const WED = 'Wed';
export const THU = 'Thu';
export const FRI = 'Fri';
export const SAT = 'Sat';
export const SUN = 'Sun';

export const STREET_ADDRESS1 = 'Address 1';
export const STREET_ADDRESS2 = 'Address 2';
export const CITY = 'City';
export const STATE = 'State';
export const ZIP_CODE = 'Zip Code';
export const COUNTRY = 'Country';

export const ACCOUNT = 'Account';
export const CREDIT_CARD = 'Credit card';
export const DEBIT_CARD = 'Debit card';
export const BANK_NAME = 'Bank Name';
export const ACCOUNT_NUMBER = 'Account Number';
export const ROUTING_NUMBER = 'Routing Number';
export const PAYMENT_TYPE = 'Payment type';
export const Pay = 'Pay';

export const OK = 'Ok';
export const YES = 'Yes';
export const NO = 'No';
export const CANCEL = 'Cancel';
export const CONFIRMATION = 'Confirmation';
export const ARE_YOU_SURE_YOU_WANT_TO_CANCEL = 'Are you sure you want to cancel?';
export const CONFIRM = 'Confirm';
export const LOADING = 'Loading';

export const STEP = 'Step';
export const OF = 'of';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const SUBMIT = 'Submit';
export const CLOSE = 'Close';
export const TOGGLE_MENUS = 'Toggle menus';

export const ENLARGED_ATTACHMENT = 'Enlarged attachment';
export const NO_DATA = 'No Data';
export const MONTH_TO_MONTH = 'Month to month';
export const LEASED = 'Leased';
export const BEDROOMS = 'Bedrooms';
export const BATHROOMS = 'Bathrooms';
export const SIZE = 'Size';
export const LOGO = 'Logo';
export const POWERED_BY_MAGICDOOR = 'Powered by MagicDoor';

export const HOME = 'Home';
export const PAYMENTS = 'Payments';
export const MAINTENANCE = 'Maintenance';
export const COMMUNICATIONS = 'Communications';
export const SERVICES = 'Services';
export const SETTINGS = 'Settings';

export const WHEN_DUE = 'When due';
export const WEEKLY = 'Weekly';
export const MONTHLY = 'Monthly';
export const TWICE_A_MONTH = 'Twice a month';
export const FIXED_AMOUNT = 'Fixed amount';
export const AMOUNT = 'Amount';
export const MAXIMUM_AMOUNT = 'Maximum amount';
export const PAYMENT_FREQUENCY = 'Payment frequency';
export const POSTED_BALANCE = 'Posted balance';
export const CURRENT_BALANCE = 'Current balance';
export const CUSTOM_AMOUNT = 'Custom amount';
export const ACTIVE = 'Active';
export const PENDING = 'Pending';
export const WORK_ORDER = 'Work order';
export const CLOSED = 'Closed';
export const PROGRESS = 'Progress';
export const IN_PROGRESS = 'In progress';
export const CANCELLED = 'Cancelled';
export const ON_HOLD = 'On hold';
export const START_DATE = 'Start date';
export const END_DATE = 'End date';
export const DATE_AND_TIME = 'Date & Time';

export const SELECT_AN_ACCOUNT = 'Select an account';
export const SELECT_A_PAYMENT_TYPE = 'Select a payment type';
export const ENTER_A_VALID_AMOUNT = 'Enter a valid amount';
export const AMOUNT_MUST_HIGHER_THAT_MINIUM_PAYMENT_AMOUNT = 'Amount must be higher than the minimum payment amount({number})';
export const SELECT_A_PAYMENT_FREQUENCY = 'Select a payment frequency';
export const SELECT_A_FUTURE_PAYMENT_START_DATE = 'Select a future payment start date';
export const SELECT_AN_END_DATE_AFTER_START_DATE = 'End date must be after star date';
export const UNKNOWN = 'Unknown';
export const UNKNOWN_DATE = 'Unknown date';
export const YESTERDAY_AT_TIME = 'Yesterday at {time}';
export const DATE_AT_TIME = '{date} at {time}';
export const NO_MESSAGES = 'No messages';

export const FUTURE = 'Future';
export const DUE = 'Due';
export const OVERDUE = 'Overdue';
export const DUE_SOON = 'Due soon';
export const FAILED = 'Failed';
export const PAID = 'Paid';
export const PAYMENT = 'Payment';
export const RETURNED = 'Returned';
export const PROCESSING = 'Processing';
export const NONE = 'None';
export const REFUND = 'Refund';
export const CREDIT = 'Credit';
export const CHARGE = 'Charge';
export const DATE = 'Date';
export const STATUS = 'Status';
export const DESCRIPTION = 'Description';
export const TYPE = 'Type';
export const CURRENT_MAINTENANCE_REQUESTS = 'Current maintenance requests';
export const NO_MAINTENANCE_REQUESTS = 'No maintenance requests';
export const NOTIFICATIONS = 'Notifications';
export const PROCESSING_FEES = 'Processing fees';
export const TOTAL = 'Total';
export const CONFIRM_PAYMENT = 'Confirm Payment';
export const NEXT_PAYMENT = 'Next payment';
export const LOW = 'Low';
export const MEDIUM = 'High';
export const HIGH = 'Medium';
export const URGENT = 'Urgent';
export const ADD_ATTACHMENTS = 'Add attachments';
export const PERMISSION_TO_ENTER = 'Permission to enter';
export const URGENCY_TEXT = 'Urgency';
export const ACCOUNT_TYPE = 'Account type';
export const MY_CHATS = 'My chats';
export const SELECT_A_CHAT = 'Select a chat to begin';
export const SCROLL_TO_BOTTOM = 'Scroll to bottom';
export const SEND_A_MESSAGE = 'Send a message to begin';
export const TYPE_SOMETHING = 'Type something';
export const APP_IS_RECOMMENDED = 'Download the app for a better experience';
export const MESSAGE = 'Message';
export const OUTSANDING_BALANCE = 'Outstanding balance';
export const MAKE_PAYMENT = 'Make payment';
export const ADD_BANK_ACCOUNT = 'Add bank account';
export const NEW_MAINTENANCE_REQUEST = 'New maintenance request';
export const FIND_YOUR_PERFECT_PLACE = 'Find your perfect place';
export const TITLE = 'Title';
export const ASSIGNED_TO = 'Assigned to: ';
export const VIEW = 'View';
export const APPROVED = 'Approved';
export const VIEW_ALL = 'View all';
export const NO_NOTIFICATIONS = 'No notifications';
export const HI = 'Hi';
export const FEET = 'ft';
export const PROPERTIES_FOR_YOU = 'Properties for you';
export const PROPERTIES_FOR_YOU_BLURB = 'Properties for you blurb';
export const CREATE_REQUEST = 'Create request';
export const CREATED = 'Created';
export const LAST_UPDATED = 'Last updated';
export const MAINTENANCE_REQUESTS = 'Maintenance requests';
export const MAINTENANCE_REQUEST_DETAILS = 'Maintenance request details';
export const GENERAL = 'General';
export const REQUESTS_PER_PAGE = 'Requests per page';
export const PREV = 'Prev';
export const SUCCESS = 'Success!';
export const CONGRATULATIONS_PAYMENT = 'Congratulations! Your payment has been received';
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_AUTOPAYMENT = 'Are you sure you want to delete this auto payment?';
export const FINISH = 'Finish';
export const ADD_NEW = 'Add new';
export const PAYMENT_METHODS = 'Payment methods';
export const AUTO_PAYMENTS = 'Auto-payments';
export const NO_AUTO_PAYMENTS = 'You have no auto-payments set up for this lease.';
export const CONNECT_BANK_ACCOUNT = 'Connect bank account';
export const TRANSACTIONS = 'Transactions';
export const NO_TRANSACTIONS = 'You have no transactions yet for this lease';
export const AUTO_PAYMENT_CREATED = 'Auto-payment was successfully created!';
export const SET_UP_AUTO_PAYMENT = 'Set up an automatic payment';
export const NO_PAYMENTS_ALLOWED = 'No payments allowed at this time';
export const BALANCE_DETAIL = 'Balance detail';
export const LIST_OF_ACCOUNTS = 'Below is a list of your connected bank accounts with ACH';
export const SELECT_A_PAYMENT_METHOD = 'Select the payment method';
export const CHOOSE_ADD_UPDATE_PAYMENT_METHOD = 'Choose, add or update your payment method';
export const SAVED_PLAID_PAYMENT_METHODS = 'Saved Plaid payment methods';
export const SAVED_STRIPE_PAYMENT_METHODS = 'Saved Stripe payment methods';
export const MAGICDOOR_USES_PLAID = 'MagicDoor uses Plaid to connect your account';
export const MAGICDOOR_USE_STRIPE = 'MagicDoor uses Stripe to Secure your transaction';
export const PLAID_CONNECT_BLURB = 'Plaid lets you securely connect your financial accounts in seconds';
export const CONNECT_EFFORTLESSLY = 'Connect effortlessly';
export const ACCEPT_TERMS_OF_USE = 'Accept terms of use';
export const ACCEPT_TERMS_OF_USE_BLURB = 'By connecting your bank account you agree to our terms of use.';

export const INVALID_USER_NAME_OR_PASSWORD = 'Invalid user name or password';
export const SOMETHING_WENT_WRONG = 'Oops! Something went wrong!';
export const SIGN_IN = 'Sign in';
export const SIGN_OUT = 'Sign out';
export const EMAIL = 'Email';
export const PASSWORD = 'Password';
export const ALL_CHATS = 'All chats';
export const UPLOADED_FILES = 'Uploaded files';
export const UPDATE_REQUEST = 'Update request';
export const BACK = 'Back';
export const CLOSE_REQUEST = 'Close request';
export const GOR_TO_APP = 'Go to app';
export const TENANT_SIGN_IN = 'Tenant sign-in';
export const HELLO = 'Hello';
export const YOU_DO_NOT_HAVE_ANY_CHATS = 'You do not have any chats!';
export const YOU_NEED_TO_PROVIDE_A_CHAT_ID = 'You need to provide a chat ID!';
export const FAILED_TO_SUBSCRIBE_TO_CHAT_MESSAGES = 'Failed to subscribe to chat messages!';
export const FAILED_TO_UNSUBSCRIBE_FROM_CHAT_MESSAGES = 'Failed to unsubscribe from chat messages!';
export const YOU_NEED_TO_PROVIDE_A_MAINTENANCE_REQUEST_ID = 'You need to provide a maintenance ID!';
export const PLEASE_PROVIDE_A_DESCRIPTION_FOR_YOUR_REQUEST = 'Please provide a description for your request!';
export const YOU_NEED_TO_CREATE_OR_SELECT_AN_AUTOPAYMENT = 'You need to create or select an auto-payment!';
export const THERE_WERE_PROBLEMS_WITH_YOUR_REQUEST = 'There were problems with your request!';
export const YOU_NEED_TO_PROVIDE_A_PAYMENT_ID = 'You need to provide a payment ID!';
export const YOU_NEED_TO_SELECT_AN_ACCOUNT = 'You need to select an account!';
export const YOU_NEED_TO_SELECT_A_LEASE_TO_PAY = 'You need to select a lease to make a payment!';
export const YOU_NEED_TO_SELECT_A_PAYMENT_TYPE = 'You need to select a payment type!';
export const YOU_NEED_TO_SELECT_A_PAYMENT_AMOUNT = 'You need to select a payment amount!';
export const YOU_CANNOT_PAY_LESS_THAN_THE_MINIMUM = 'You cannot pay less than the minimum amount!';
export const YOU_NEED_TO_SELECT_A_LEASE = 'You need to select a lease';
export const INVALID_PAYMENT_AMOUNT = 'Invalid amount! You have to pay at least the minimum payment amount!';
export const COMPANY_NOT_ONBOARDED = 'It seems your company has not been onboarded yet. Please contact your property manager.';
export const YOU_DO_NOT_HAVE_ANY_LEASES = 'You do not have any leases!';
export const YOU_DO_NOT_HAVE_ANY_AVAILABLE_LEASE = 'You do not have any available leases!';
export const YOU_NO_NOT_SELECT_A_LEASE = 'You do not select a lease!';
export const NO_RENTAL_HISTORY_ERROR = 'You must provide at least one rental history!';
export const HTTP_STATUS_CODE = 'HTTP status code: ';
export const YOU_CANNOT_MAKE_A_PAYMENT_AT_THIS_TIME = 'You cannot make payments at this time. Contact your property manager!';
export const TERMS_OF_USE = 'Terms of use';
export const BY_AGREEING_TO_PAY_LINE1 = 'By clicking on confirm, you are agreeing to make this payment to';
export const BY_AGREEING_TO_PAY = 'By clicking on confirm, you are agreeing to make this payment';
export const BY_AGREEING_TO_PAY_ACH_BLURB = 'The payment will be made using ACH on date';
export const REVOKE_PAYMENT_AUTHORIZATION_BLURB = 'To revoke this authorization contact property manager';
export const YOUR_JOURNEY_BEGINS = 'Your journey begins here';
export const BY_AGREEING_TO_PAY_WITH_PLAID = 'By clicking on confirm, you are able to make this payment with Plaid account';

export const EDIT = 'Edit';
export const REMOVE = 'Remove';
export const APPLICANT_INFORMATION = 'Applicant information';
export const POTENTIAL_UNITS = 'Potential units';
export const UNIT_OF_INTEREST = 'Unit of interest';
export const ALL_UNITS = 'All units';
export const OPEN_TO_ALL_UNITS = 'Open to all units';

export const RENTAL_HISTORY = 'Rental history';
export const WORK_HISTORY = 'Work history and income verification';
export const TERM_AND_CONDITIONS = 'Terms and conditions';
export const CONFIRM_YOUR_IDENTITY = 'Confirm your identity';
export const QUESTIONS = 'Questions';
export const RENTAL_APPLICATION = 'Rental application';
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_ACCOUNT = 'Are you sure you want to delete this account';
export const ARE_YOU_SURE_YOU_WANT_TO_UNLINK_ALL_PLAID = 'Are you sure you would like to unlink all your Plaid accounts';
export const SUBMIT_A_RENTAL_APPLICATION = 'Submit a rental application';
export const WE_CURRENTLY_DO_NOT_HAVE_ANY_AVAILABLE_UNITS =
  "We currently don't have any available units, but you are always welcome to submit a rental application to us";
export const ADD_CREDIT_DEBIT_CARD = 'Add credit/debit card';
export const UNLINK = 'Unlink';
export const RESYNC = 'Resync';
export const ADD_CARD = 'Add card';
export const MAGICDOOR_TERMS = 'MagicDoor Terms and Conditions';
export const TRANS_UNION_TERMS = 'TransUnion Terms and Conditions';
export const LINK_BANK_ACCOUNTS = 'Link bank accounts';
export const BELOW_IS_A_LIST_OF_PLAID_ACCOUNTS = 'Below is a list of all your connected bank accounts';
export const CREDIT_DEBIT_CARDS = 'Credit/Debit cards';
export const BELOW_IS_A_LIST_OF_STRIPE_ACCOUNTS = 'Below is a list of all your connected  credit/debit cards';
export const NO_LINKED_ACCOUNTS = 'There are no linked accounts';
export const ADD_RENTAL_HISTORY = 'Add rental history';
export const REASON_FOR_LEAVING = 'Reason for leaving';
export const LANDLORD_NAME = 'Landlord name';
export const LANDLORD_PHONE = 'Landlord phone';
export const RENT = 'Rent';
export const MOVE_IN_DATE = 'Move-in date';
export const MOVE_OUT_DATE = 'Move-out date';
export const I_HAVE_READ_AND_AGREED_TO_THE_TERMS = 'I have read and agreed to the terms of service';
export const SELECTED_UNITS = 'Selected units';
export const ADD_EMPLOYMENT = 'Add employment';
export const EMPLOYMENT_HISTORY = 'Employment history';
export const ANNUAL_INCOME = 'Annual income';
export const NAME = 'Name';
export const PHONE = 'Phone';
export const POSITION = 'Position';
export const SALARY = 'Salary';
export const BANK_STATEMENT = 'Bank statement';
export const UPLOAD_GOVERNMENT_ID = 'Upload government ID';
export const UPLOAD_BANK_STATEMENT = 'Upload bank statement';
export const TRANS_UNION_CONNECTION = 'TransUnion connection';
export const START_SCREENING = 'Start screening';
export const PAYMENT_DETAILS = 'Payment details';
export const SUBMIT_ANSWERS = 'Submit answers';
export const PAYMENT_PROCESSING = 'Payment processing';
export const PAYMENT_PROCESSING_BLURB = 'Payment processing blurb';
export const DONE = 'Done';
export const RENTAL_APPLICATION_SUCCESSFULLY_SUBMITTED = 'Your rental application was successfully submitted';
export const RENTAL_APPLICATION_NOTIFICATION = 'The application status will be notified by email. Please check your email';
export const CONNECTING_TO_TRANS_UNION = 'Connecting to TransUnion to Prepare Questionnaire and Initiate ID Verification';
export const CONNECTING_TO_TRANS_UNION_BLURB = 'Connecting to TransUnion blurb';
export const CONTACT_CUSTOMER_SUPPORT = 'For completing your screening process, please contact our customer support at';
export const CONTACT_CUSTOMER_SUPPORT_BLURB = 'Customer support blurb';
export const UNABLE_TO_VERIFY_IDENTITY = 'We are unable to verify your identity online';
export const RENTER_SCREENING_ID = 'Renter screening ID: {renterID}';
export const PAGE_NOT_FOUND = 'Page Not Found';
export const PAGE_NOT_FOUND_BLURB_1 = 'Page not found blurb 1';
export const PAGE_NOT_FOUND_BLURB_2 = 'Page not found blurb 2';

export const SCAN_THE_QRCODE = 'Scan the code to open the migicdoor program.';
export const CURRENT_LEASE = 'Current lease';
export const NO_LEASE_YET = 'No lease yet';
export const BALANCES = 'Balances';
export const ONGOING_MAINTENANCE_REQUESTS = 'Ongoing maintenance requests';
export const NA = 'N/A';
export const GRANT_ENTRY = 'Grant Entry';
export const PREFERRED_MAINTENANCE_TIME = 'Preferred maintenance time';
export const ALL_MAINTEANCE_REQUESTS = 'All maintenance requests';

export const EVICTION = 'Eviction';
export const EXPRIED = 'Expired';
export const DRAFT = 'Draft';
export const ENDED_LEASE = 'Ended Lease';

export const LOW_AMOUNT = 'The payment amount is too low for processing. Please adjust the amount and try again.';
export const BANK_ACCOUNT_NOT_LINKED = 'Your bank account is not linked. Please link a bank account to proceed.';
export const PAYMENT_AMOUNT_TOO_LOW = 'The payment amount is below the minimum threshold. Please increase the amount and try again.';
export const INSUFFICIENT_FUNDS = 'There are insufficient funds in your account. Please add funds and try again.';
export const ACCOUNT_NOT_MAPPED = "There's an issue with your tenant account. Please contact support for assistance.";
export const TRANSFER_LIMIT_REACHED =
  'You have reached your transfer limit. Please try again later or contact support for limit adjustment.';
export const ACCOUNT_BLOCKED = 'Your account is currently blocked. Please contact support for further details.';
export const PAYMENT_EVICTION = 'Payments are restricted due to an ongoing eviction process. Please contact support for more information.';
export const PAYMENT_UNKNOWN_ERROR = 'An unknown error occurred. Please try again later. If the issue persists, contact support.';
export const FIRST_NAME = 'First name';
export const LAST_NAME = 'Last name';
export const SSN = 'SSN';
export const DATE_OF_BIRTH = 'Date of birth';
export const GOVERNMENT_ID = 'Government ID';
export const WELCOME_TO_MAGIC_DOOR =
  'Welcome to MagicDoor. By accessing our website, mobile application, or using our services, you agree to be bound by these Terms and Conditions.';
export const MAGIC_DOOR_PROVIDES_A_PLATFORM =
  'MagicDoor provides a digital platform for property management. Our services include, but are not limited to:';
export const RENT_COLLECTION = 'Rent collection';
export const MAINTENANCE_MANAGEMENT = 'Maintenance management';
export const TENANT_COMMUNICATION = 'Tenant communication';
export const FINANCIAL_REPORTING = 'Financial reporting';
export const RENTAL_APPLICATION_CHARGES_NON_REFUNDABLE = 'Please note that Rental Application charges are non-refundable.';
export const VIEW_IN_FULL_SCREEN = 'View in fullscreen';
export const SHOW = 'Show';
export const HIDE = 'Hide';
export const FIRST_NAME_IS_INVALID = 'First name is not valid';
export const LAST_NAME_IS_INVALID = 'Last name is not valid';
export const EMAIL_IS_INVALID = 'Email is not valid';
export const PHONE_NUMBER_IS_INVALID = 'Phone number is not valid';
export const DATE_OF_BIRTH_IS_INVALID = 'Date of birth is not valid';
export const DATE_OF_BIRTH_IS_GREATER = 'Age cannot be greater than 125 years';
export const DATE_OF_BIRTH_IS_LESSER = 'Age cannot be less than 18 years';
export const ANNUAL_INCOME_IS_INVALID = 'Annual income is not valid';
export const SSN_IS_INVALID = 'SSN is not valid';
export const YOU_HAVE_TO_ACCEPT_THIS_LICENSE_AGREEMENT = 'You have to accept this license agreement';
export const EMPLOYER_NAME_IS_INVALID = 'Employer name is not valid';
export const SALARY_IS_INVALID = 'Salary is not valid';
export const POSITION_TITLE_IS_INVALID = 'Position/title is not valid';
export const START_DATE_IS_INVALID = 'Start date is not valid';
export const END_DATE_IS_INVALID = 'End date is not valid';
export const END_DATE_IS_INVALID_EARLIER = 'End date cannot be earlier than start date';
export const ADDRESS_IS_INVALID = 'Address is not valid';
export const STREET_ADDRESS_1_IS_INVALID = 'Street address 1 is not valid';
export const STREET_ADDRESS_2_IS_INVALID = 'Street address 2 is not valid';
export const REASON_FOR_LEAVING_IS_INVALID = 'The length of the reason for leaving cannot exceed 500';
export const CITY_IS_INVALID = 'City is not valid';
export const STATE_IS_INVALID = 'State is not valid';
export const ZIP_CODE_IS_REQUIRED = 'Zip code is required';
export const ZIP_CODE_IS_INVALID = 'The U.S. zipcode must consist of 5 digits';
export const COUNTRY_IS_INVALID = 'Country is not valid';
export const LANDLORD_NAME_IS_INVALID = 'Landlord name is not valid';
export const LANDLORD_PHONE_NUMBER_IS_INVALID = 'Landlord phone number is not valid';
export const RENT_IS_INVALID = 'Rent is not valid';
export const MOVE_IN_DATE_IS_INVALID = 'Move in date is not valid';
export const MOVE_OUT_DATE_IS_INVALID = 'Move out date is not valid';
export const MOVE_IN_DATE_IS_LATER = 'Move in date cannot be later than move out date';
export const MOVE_OUT_DATE_IS_EARLIER = 'Move out date cannot be earlier than move in date';

export const PAY = 'Pay';
export const RENTAL_APPLICATION_FEE_BLURB =
  'You are required to pay a non-refundable fee of {amount} to submit your application, that will go towards the screening costs.';

export const NO_COMMUNICATIONS = 'No communications';
export const RENTAL_APPLICATION_NOT_FOUND = 'Rental application not found';
export const QUESTIONAIRE = 'Questionaire';
export const APPLICATION_SUBMITTED = 'Application submitted';
export const MANUAL_VERIFICATION = 'Manual verification';
export const OOPS = 'Oops';
export const THIS_LEASE_HAS_BEEN_DEACTIVATED_TIPS =
  'This lease has been deactivated and you will not be able to perform any actions, please contact the administrator if you have any questions.';
export const CURRENT_PASSWORD = 'Current password';
export const NEW_PASSWORD = 'New password';
export const CONFIRM_NEW_PASSWORD = 'Confirm new password';
export const ENTER_PASSWORD = 'Enter password';
export const ENTER_NEW_PASSWORD = 'Enter new password';
export const PASSWORD_DO_NOT_MATCH = 'Passwords do not match';
export const UPDATE_PASSWORD = 'Update password';
export const ENTER_THE_CURRENT_PASSWORD_AND_NEW_PASSWORD = 'Enter the current password and new password';
export const LOGIN_PASSWORD = 'Login & Password';
export const SAVE = 'Save';
export const SETTINGS_UPDATED_MESSAGE = 'The settings has been updated, please save it in time';
export const NO_UPDATES_SAVED_MESSAGE = 'Note that there are no updates yet saved.';
export const BASIC_INFORMATION = 'Basic information';
export const UPDATE_TENANT_SUCCESS = 'Update tenant information successfully';
export const LANGUAGE = 'language';
export const PASSWORD_UPDATED_SUCCESSFULLY = 'Password updated successfully';
export const TENANT_PROFILE = 'Tenant profile';
export const UPDATE = 'Update';
export const DELETE_BANK_ACCOUNT = 'Delete Bank Account';
export const ARE_YOU_SURE_TO_DELETE_THIS_BANK_ACCOUNT = 'Are you sure to delete this bank account?';

export const PLAID_LINKED_BANKS = 'Plaid linked banks';
export const ALL_BANK_ACCOUNTS_LISTED = 'Below is a list of all your connected banks. Click on a bank to view its associated accounts.';
export const ADD_ANOTHER_ACCOUNT = 'Add Another Account';
export const DELETE_AUTO_PAYMENT = 'Delete Auto-Payment';
export const DELETE_AUTO_PAYMENT_CONFIRMATION = 'After this automatic payment is removed, subsequent bills need to be paid manually.';
export const YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_LINKED_TO_MAGICDOOR = 'Your account has been successfully linked to MagicDoor.';
export const PAYMENT_START_DATE = 'PAYMENT START DATE';
export const PAYMENT_START_DATE_PLACEHOLDER = 'Payment start date';
export const PAYMENT_END_DATE = 'PAYMENT END DATE';
export const PAYMENT_END_DATE_PLACEHOLDER = 'Payment end date';
export const CURRENT_BALANCE_DETAIL = 'CURRENT BALANCE DETAIL';
export const BALANCE_TYPE = 'Balance Type';
export const POSTED_BALANCE_TIPS =
  'The sum of charges that have been officially posted, including pending transactions and future charges.';
export const CURRENT_BALANCE_TIPS =
  'The real-time balance of your lease, taking into account all payments, credits, and charges that have occurred to date.';
export const AUTO_PAYMENT_DETAIL = 'Auto-Payment Detail';
export const SETTING_AUTOMATIC_PAYMENT_SUCCEEDED = 'Setting Automatic Payment Succeeded';
export const SECURELY_CONNECT_TO_YOUR_BANK_WITH_PLAID = 'Securely Connect to Your Bank with Plaid';
export const TO_FACILITATE_SEAMLESS_RENT_PAYMENTS =
  'To facilitate seamless rent payments, we need to connect to your bank securely through Plaid.';
export const PLAID_LETS_YOU_SECURELY_CONNECT_YOUR_FINANCIAL_ACCOUNTS_IN_SECONDS =
  'Plaid lets you securely connect your financial accounts in seconds';
export const STRIPE_LETS_YOU_SECURELY_CONNECT_YOUR_FINANCIAL_ACCOUNTS_IN_SECONDS =
  'Stripe lets you securely connect your financial accounts in seconds';
export const PLAID_DOESNT_SELL_PERSONAL_INFO = 'Plaid doesn’t sell personal info, and will only use it with your permission';
export const STRIPE_DOESNT_SELL_PERSONAL_INFO = 'Stripe doesn’t sell personal info, and will only use it with your permission';
export const BY_SELECTING_NEXT_YOU_AGREE_TO_THE = 'By selecting ‘’Next’’ you agree to the ';
export const PLAID_END_USER_PRIVACY_POLICY = 'Plaid End User Privacy Policy';
export const INVOICE_DETAILS = 'Invoice details';
export const PAYMENT_PLATFORM_FEES = 'Payment Platform Fees';
export const TOTAL_AMOUNT = 'TOTAL AMOUNT:';
export const HAVE_PAID = 'Have Paid';
export const PAYMENT_SUCCESS = 'Payment success!';
export const RENT_PAYMENT_SUCCESSFULLY_TRANSFERRED = 'Congratulations! Your rent payment has been successfully transferred using Plaid.';
export const OTHER_AMOUNT = 'Other Amount';
export const SELECT_THE_PAYMENT_METHOD_YOU_WANT_TO_USE = 'Select the payment method you want to use';
export const ADD_NEW_ACCOUNT = 'Add New Account';
export const ADD_NEW_CARD = 'Add New Card';
export const CURRENT_SELECTED = 'Currently selected:';
export const UPDATE_PAYMENT_METHODS = 'Update Payment Methods';
export const SET_UP = 'Set Up';
export const YOU_HAVE_NO_AUTO_PAYMENTS = 'You have no auto payments set up for this lease';
export const START_AND_EXPIRE_DATE = 'Start and expire date';
export const EXPIRE_DATE = 'Expire Date';
export const DELETE = 'Delete';
export const RUNNING_BALANCE = 'Running Balance';
export const BALANCE = 'Balance';
export const RENT_DETAILS = 'Rent Details';

export const TRANSACTION_DETAILS = 'Transaction Details';
export const MEMO = 'Memo';
export const TRANSACTION_DATE = 'Transaction Date';

export const REQUIRED_MESSAGE = '{name} is required';
export const OVERFLOW_MESSAGE = '{name} must be {length} characters or less';
export const RANGE_MESSAGE = '{name} must be between {min} and {max} characters';
export const MIN_LENGTH_MESSAGE = '{name} must be at least {minLength} characters';

export const VISA = 'Visa';
export const MASTERCARD = 'MasterCard';
export const AMERICAN_EXPRESS = 'American Express';
export const JCB = 'JCB';
export const UNION_PAY = 'Union Pay';
export const DINERS_CLUB = 'Diners Club';
export const PLAID = 'Plaid';

export const YOU_DO_NOT_PROVIDE_A_TRANSACTION_ID = 'You do not provide a transaction id';
export const CAN_NOT_FIND_THE_TRANSACTION_RECORD = 'Can not find the transaction record';
export const THERE_WERE_PROBLEMS_WITH_YOUR_RESULT = 'There were problems with your result';
export const NO_LEASE_SELECTED = 'No lease selected!';
export const COULD_NOT_FIND_PAYMENT_OPTIONS_FOR_THIS_LEASE = 'Could not find payment options for this lease!';
export const ELECTRONIC_BANK_TRANSFER = 'Electronic Bank Transfer';
export const ELECTRONIC_BANK_TRANSFER_FEE = '{number} processing fee';
export const CREDIT_DEBIT_CARD = 'Credit/Debit Card';
export const CREDIT_DEBIT_CARD_FEE = '{number} transaction fee';
export const NO_PLAID_ACCOUNTS_YET = 'No plaid accounts yet';
export const NO_CREDIT_AND_DEBIT_CARD_YET = 'No credit/debit cards yet';
export const REQUESTING_RESULTS_FROM_STRIPE = 'Requesting results from Stripe';
export const NO_CURRENT_BALANCE_DUE = 'No current balance due';
export const PAYMENT_FEATURE_IS_CURRENTLY_UNAVAILABLE =
  'Payment feature is currently unavailable, please contact support for more information';
export const THIS_LEASE_CURRENTLY_CAN_NOT_ACCEPTS_PAYMENTS =
  'This lease currently can not accepts payments, please contact support for more information';
export const THIS_LEASE_CURRENTLY_CAN_NOT_PAY_WITH_CARDS =
  'This lease currently can not pay with credit card or debit card, please contact support for more information';
export const THIS_LEASE_CURRENTLY_CAN_NOT_PAY_WITH_ELECTRONIC_BANK_TRANSFER =
  'This lease currently can not pay with electronic bank transfer, please contact support for more information';
export const CHECKING = 'Checking';
export const SAVINGS = 'Savings';

export enum RentalHistoryField {
  Address1 = 'address1',
  Address2 = 'address2',
  ReasonForLeaving = 'reasonForLeaving',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  Country = 'country',
  LandlordName = 'landlordName',
  LandlordPhone = 'landlordPhone',
  Rent = 'rent',
  MoveInDate = 'moveInDate',
  MoveOutDate = 'moveOutDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Name = 'name',
  Phone = 'phone',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  SSN = 'ssn',
  DateOfBirth = 'dateOfBirth',
  Position = 'position',
  Salary = 'salary',
}
export const COMPANY_NAME = 'Company name';
export const FOLLOW_US = 'Follow us';
export const ABOUT_US = 'About us';
export const PROPERTIES = 'Properties';
export const CONTACT_US = 'Contact us';
export const SIGN_IN_AS_MANAGER = 'Sign In as Manager';
export const LET_US_GUIDE_YOUR_HOME = 'Let us guide your home';
export const BANNER_TITLE = "Discover a place you'll love to live";
export const SALE = 'Sale';
export const SEARCH = 'Search';
export const PROPERTY_FOR_YOU_TITLE = 'Property for you';
export const PROPERTY_FOR_YOU_SUBTITLE = 'Search and save a few homes you like and wellfind recommendations for you.';
export const FT = 'ft';
export const PROPERTY_BY_AREA_TITLE = 'Property by area';
export const PROPERTY_BY_AREA_SUBTITLE =
  'Take a deep dive and browse homes or apartments for rent and local insights to find what is right for you.';
export const INPUT_AREA_SEARCH = 'Input area search ...';
export const LISTINGS = 'Listings';
export const NEWLY_LISTED_RENTALS_TITLE = 'Newly listed rentals in';
export const NEWLY_LISTED_RENTALS_SUBTITLE =
  'Take a deep dive and browse homes or apartments for rent and local insights to find what is right for you.';
export const MORE = 'More';
export const DISCOVER = 'Discover';
export const QUICK_LINKS = 'Quick Links';
export const REACH_US = 'Reach us';
export const CONTACT_INFORMATIONS_TITLE = 'Contact informations';
export const CONTACT_INFORMATIONS_SBU_TITLE =
  'Here is our contact information. Feel free to reach out to us, and we will be happy to answer any questions you have.';
export const SEND_MESSAGE = 'Send message';
export const SEND_MESSAGE_DESC =
  'If you have any questions, please leave us a message. Our professional house manager will be happy to assist you.';
export const PINNED = 'Pinned';
export const LIKE_STORAGE_KEY = 'likedUnit';
export const FEATURED_RENTAL_LISTINGS = 'Featured Rental Listings';
export const FEATURED_RENTAL_LISTINGS_SUBTITLE =
  'Discover the most sought-after rental properties handpicked by our experienced property managers. ';

export const PRICE = 'Price';
export const MIN = 'Min';
export const MAX = 'Max';
export const NO_MIN = 'no min';
export const NO_MAX = 'no max';
export const HOME_TYPE = 'Home type';
export const BEDS_AND_BATHS = 'Beds & Baths';
export const ANY = 'Any';
export const SINGLE_FAMILY = 'singleFamily';
export const SINGLE_FAMILY_DISPLAY = 'Single family';
export const MULTI_FAMILY = 'multiFamily';
export const MULTI_FAMILY_DISPLAY = 'Multi family';
export const COMMERCIAL = 'commercial';
export const CONDO = 'condo';
export const TOWN_HOME = 'townHome';
export const TOWN_HOUSE = 'Town house';
export const OTHER = 'other';
export const RESET = 'Reset';
export const SAVE_SEARCH = 'Save search';
export const CONTACT_MANAGER = 'Contact manager';
export const PETS = 'Pets';
export const AC = 'A/C';
export const PARKING = 'Parking';
export const UTILITIES_INCLUDED = 'Utilities included';
export const OUTDOOR = 'Outdoor';
export const LISTED = 'Listed';
export const CATS_ALLOWED = 'Cats allowed';
export const DOGS_ALLOWED = 'Dogs allowed';
export const NO_LARGE_DOGS_ALLOWED = 'No large dogs allowed';
export const SMALL_DOGS_ALLOWED = 'Small dogs allowed';
export const NO_SMALL_DOGS_ALLOWED = 'No small dogs allowed';
export const MONTH = 'Month';
export const SQFT = 'sqft';
export const REQUEST_TO_APPLY = 'Request to apply';
export const OVERVIEW = 'Overview';
export const HOME_HIGHLIGHTS = 'Home highlights';
export const FACTS_AND_FEATURES = 'Facts and features';
export const LOCAL_INFORMATION = 'Local information';
export const SELECT_PRICE = 'Select price';
export const SELECT_HOME_TYPE = 'Select home type';
export const SELECT_MOVE_IN_DATE = 'Select move in date';
export const SLIDE_UP_TO_LOAD_MORE = 'Slide up to load more';
export const NO_INFO = '--';
export const APPLY = 'Apply';
export const PROPERTY_DETAIL = 'Property Detail';

export const ZERO_RESULTS_DESC = 'No results found for the given address. Please check the address and try again.';
export const MAP_ERROR_DESC = 'An error occurred while loading the map. Please try again later.';
export const CENTER_POINT = 'Center Point';
export const EMPTY_DESC = 'Nothing here yet. ';

export const WATER_AND_SEWER = 'Water & Sewer';
export const ELECTRICITY = 'Electricity';
export const GAS = 'Gas';
export const TRASH = 'Trash';
export const INTERNET_CABLE = 'Internet/cable';
export const HEATING_AIR_CONDITIONING = 'Heating/air conditioning';
export const IN_UNIT_WASHER_DRYER = 'In-unit washer/dryer';
export const DISHWASHER = 'Dishwasher';
export const MICROWAVE = 'Microwave';
export const STAINLESS_STEEL_APPLIANCES = 'Stainless steel appliances';
export const HARDWOOD_FLOORING = 'Hardwood flooring';
export const CENTRAL_AC_HEATING = 'Central AC/heating';
export const WALK_IN_CLOSETS = 'Walk-in closets';
export const HIGH_SPEED_INTERNET = 'High-speed internet';
export const FIREPLACE = 'Fireplace';
export const PATIO = 'Patio';
export const BBQ_GRILLS_OR_PICNIC_AREA = 'BBQ grills or picnic area';
export const ROOFTOP_DECK = 'Rooftop deck';
export const PARKING_SPACE = 'Parking space';
export const GYM = 'Gym';
export const SWIMMING_POOL = 'Swimming pool';
export const COMMUNITY_CLUBHOUSE = 'Community clubhouse';
export const PLAYGROUND_OR_DOG_PARK = 'Playground or dog park';
export const LAUNDRY_FACILITIES = 'Laundry facilities';
export const PARKING_OR_GARAGE = 'Parking or garage';
export const SECURITY_OR_CONTROLLED_ACCESS = '24/7 security or controlled access';

export const INCLUDED_UTILITIES = 'Included utilities';
export const INTERIOR_AMENITIES = 'Interior amenities';
export const OUTDOOR_AMENITIES = 'Outdoor amenities';
export const COMMUNITY_AMENITIES = 'Community amenities';
export const SIGN_HERE = 'Sign here!';

export const SEARCH_FOR_YOUR_DREAM_HOME = 'Search dream home';
export const FILTER = 'Filter';
export const AS = 'as';
export const THRESHOLD = 'threshold';
export const CHILDREN = 'children';
export const FILTERS = 'Filters';
export const LOADER = 'loader';
export const ENDED = 'ended';
export const ON_REACH_END = 'onReachEnd';
export const END_MESSAGE = 'endMessage';
export const OL = 'ol';
export const UL = 'ul';
export const LI = 'li';
export const DIV = 'div';
export const EN = 'en';
export const UNAVAILABLE = 'unavailable';
export const OR_LESS = 'or less';
export const OR_MORE = 'or more';
export const ANY_PRICE = 'Any price';
export const PRIVACY_POLICY = 'Privacy policy';
export const TERMS_OF_SERVICE = 'Terms of service';
export const EQUAL_HOUSING_OPPORTUNITY = 'Equal housing opportunity';
export const ALL_RIGHTS_RESERVED = 'All rights reserved';
export const MAX_NUMBER = 2147483647;
export const NO_PETS_ALLOWED = 'No pets allowed';
export const ALLOWED = 'Allowed';
export const MONTH_PET_FEES = 'Monthly pet fees';
export const NO_LIMIT = 'No limit';
export const PET_DEPOSIT = 'Pet deposit';
export const SMALL_WEIGHT = 'Small (under 22.0462 pounds)';
export const MEDIUM_WEIGHT = 'Medium (22.0462 - 55.1156 pounds)';
export const LARGE_WEIGHT = 'Large (55.1156 - 110.2311 pounds)';
export const ALL_ALLOWED = 'All allowed';
export const CAT = 'Cat';
export const DOG = 'Dog';
export const FISH = 'Fish';
export const BIRD = 'Bird';
export const REPTILE = 'Reptile';
